import { proxy, useSnapshot } from "valtio";

export const state = proxy({
	current: null,
	items: {
		front: "#000",
		back: "#000",
		strip: "#fff",
		handle: "#000",
		wheel: "#000",
		side: "#000",
	},
});

// export const snap = useSnapshot(state);