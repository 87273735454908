import React, { useEffect, useState } from "react";
import App1 from "./App1";
import { state , snap } from './colors';
import axios from "axios";

export default function App() {

    const [loading , setLoading] = useState(false);
    const [sizes , setSizes] = useState([]);
    const [form , setForm] = useState({
        size : '',
        qty : 1,
        varients : {
            body : state.items.back,
            cover : state.items.front,
            frame : state.items.side
        },
        cover_checker : 'off',
        customize : 'off',
        letters : 3,
        ep : '',

    });

    const onChangeColors = (name,color) => {
        setForm((preVal)=>({
            ...preVal,
            varients : {
                ...preVal.varients,
                [name] : color
            }
        }));
    }

    const onSubmitHandler = async(e) => {
        e.preventDefault();
        console.log(form);
        let str = Object.entries(form).map(([key, val]) => `${key}=${val}`).join('&');
        setLoading(true);
        
        let x = await axios.get('https://easypackluggage.com/home/v1/add-to-cart?'+str,{
            headers : {
                Accept :'application/json',
            }
        }).then(()=>{
            console.log(x);
            setLoading(false);
            window.location.replace('https://easypackluggage.com/home/shop-cart');
        }).catch((e)=>{
            setLoading(false);
            console.log(e);
        });

    }

    const getSizes = async() => {
        let x = await axios.get('https://easypackluggage.com/home/api/v1/get-sizes',{
            headers : {
                Accept : 'application/json',
            }
        });
        setSizes(x.data);
        console.log("x",x.data);
    }

    useEffect(()=>{
        getSizes();
    },[]);

	return (
		<>
        <section className="customization_section">
        <div className="container pt-5">
            <div className="top_heading text-center">
                <h2>Customize your <span>EasyPack Luggage</span><br/>
                    At No Additional Cost</h2>
            </div>
            <div className="customization_start_here">
                <div className="row">
                    <div className="col-md-12 col-lg-6 pr-4">
                        <div className="left_side_start">
                            <div className="bag_lg_photo" id="customizationModule">
                                <App1 />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6" id="resposive_padding">
                        <div className="luggage_description">
                            <div className="the_heading">
                                <h5>
                                    SELECT THE SIZE OF LUGGAGE YOU WOULD LIKE
                                    TO PURCHASE
                                </h5>
                            </div>
                            <form method="get" action="https://easypackluggage.com/home/v1/add-to-cart" 
                            // onSubmit={onSubmitHandler}
                            >
                                {
                                    sizes.map((val,key)=>(
                                        <div className="luggage_list">
                                            <input 
                                                type="radio" 
                                                name="size" 
                                                required={true}
                                                hidden 
                                                value={val?.id}
                                                className="the_bags_list"
                                                checked={( parseInt(form?.size) === parseInt(val?.id) )? true : false} 
                                                id={`bag${key}`} 
                                                onClick={(e)=>{
                                                    setForm((preVal)=>({
                                                        ...preVal,
                                                        size : val?.id
                                                    }));
                                                }}
                                            />
                                        <label for={`bag${key}`}>
                                            <div className="single_lugage">
                                                <div className="luggae_details">
                                                    <figure>
                                                        <img
                                                            src="https://easypackluggage.com/home/public/assets/front/images/des.png"
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <div className="desc">
                                                        <span className="price">${val?.price}</span>
                                                        <span className="name">{val?.size}</span>
                                                    </div>
                                                </div>
                                                {
                                                    parseInt(form?.size) === parseInt(val?.id) &&
                                                    <div className="lugage_quantity">
                                                        <span clas>Quantity:</span>
                                                        <input 
                                                            type="number" 
                                                            name="qty" 
                                                            required={true}
                                                            value={form.qty} 
                                                            min="1"
                                                            className="quantity"
                                                            id="quantity1" 
                                                            onChange={(e)=>{
                                                                setForm((preVal)=>({
                                                                    ...preVal,
                                                                    qty : parseInt(e.target.value)
                                                                }));
                                                            }}
                                                        />
                                                        <div className="quantity_controller">
                                                            <span className="increment_quantity"><i
                                                                    className="fa fa-angle-up increment"></i></span>
                                                            <span className="decrement_quantity"><i
                                                                    className="fa fa-angle-down decrement"></i></span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </label>
                                    </div>
                                    ))
                                }

                            <div className="luggage_steps">
                                <div className="single_step">
                                    <div className="text">
                                        <span className="step_no">Step 2</span>
                                        <h4>COLOR OF THE BODY</h4>
                                        <p>Select the color of your body</p>
                                    </div>
                                    <div className="colors">
                                        <div className="single_color">
                                            <input type="radio" name="body" value={'#000000'} hidden id="body_colors_1"
                                                className="body_colors"/>
                                            <label for="body_colors_1" 
                                            onClick={(color) => {
                                                state.items['back'] = "#000000";
                                                onChangeColors('body',"#000000");
                                            }}>
                                                <span className="black"></span>
                                            </label>
                                        </div>
                                        <div className="single_color">
                                            <input type="radio" name="body" value={'#c61a34'} hidden id="body_colors_2"
                                                className="body_colors" />
                                            <label for="body_colors_2" onClick={(color) => {
                                                state.items['back'] = "#c61a34";
                                                onChangeColors('body',"#c61a34");
                                            }}>
                                                <span className="red"></span>
                                            </label>
                                        </div>
                                        <div className="single_color">
                                            <input type="radio" name="body" value={'#104388'} hidden id="body_colors_3"
                                                className="body_colors" />
                                            <label for="body_colors_3" onClick={(color) => {
                                                state.items['back'] = "#104388";
                                                onChangeColors('body',"#104388");
                                            }}>
                                                <span className="blue"></span>
                                            </label>
                                        </div>
                                        <div className="single_color">
                                            <input type="radio" name="body" value={'#f6f6f6'} hidden id="body_colors_4"
                                                className="body_colors" />
                                            <label for="body_colors_4" onClick={(color) => {
                                                state.items['back'] = "#f6f6f6";
                                                onChangeColors('body',"#f6f6f6");
                                            }}>
                                                <span className="white"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="single_step">
                                    <div className="text">
                                        <span className="step_no">Step 3</span>
                                        <h4>COLOR OF THE Cover</h4>
                                        <p>Select the color of your Cover</p>
                                    </div>
                                    <div className="colors">
                                        <div className="single_color">
                                            <input type="radio" name="cover" value={'#000000'} hidden id="cover_colors_1"
                                                className="body_colors" />
                                            <label for="cover_colors_1" onClick={(color) => {
                                                state.items['front'] = "#000000";
                                                onChangeColors('cover',"#000000");
                                            }}>
                                                <span className="black"></span>
                                            </label>
                                        </div>
                                        <div className="single_color">
                                            <input type="radio" name="cover" value={'#104388'} hidden id="cover_colors_3"
                                                className="body_colors" />
                                            <label for="cover_colors_3" onClick={(color) => {
                                                state.items['front'] = "#104388";
                                                onChangeColors('cover',"#104388");
                                            }}>
                                                <span className="blue"></span>
                                            </label>
                                        </div>
                                        <div className="single_color">
                                            <input type="radio" name="cover" value={'#c61a34'} hidden id="cover_colors_2"
                                                className="body_colors"/>
                                            <label for="cover_colors_2" onClick={(color) => {
                                                state.items['front'] = "#c61a34";
                                                onChangeColors('cover',"#c61a34");
                                            }}>
                                                <span className="red"></span>
                                            </label>
                                        </div>
                                        <div className="single_color">
                                            <input type="radio" name="cover" value={'#c8c8c6'} hidden id="cover_colors_4"
                                                className="body_colors"/>
                                            <label for="cover_colors_4" onClick={(color) => {
                                                state.items['front'] = "#c8c8c6";
                                                onChangeColors('cover',"#c8c8c6");
                                            }}>
                                                <span className="white_05"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="single_step">
                                    <div className="text">
                                        <span className="step_no">Step 4</span>
                                        <h4>COLOR OF THE Frame</h4>
                                        <p>Select the color of your Frame</p>
                                    </div>
                                    <div className="colors">
                                        <div className="single_color">
                                            <input type="radio" name="frame" value={'#000000'} hidden id="trim_colors_1"
                                                className="body_colors"/>
                                            <label for="trim_colors_1" onClick={(color) => {
                                                state.items['wheel'] = "#000000";
                                                // state.items['handle'] = "#000000";
                                                // state.items['strip'] = "#000000";
                                                state.items['side'] = "#000000";
                                                onChangeColors('frame',"#000000");
                                            }}>
                                                <span className="black"></span>
                                            </label>
                                        </div>
                                        <div className="single_color">
                                            <input type="radio" name="frame" value={'#c61a34'} hidden id="trim_colors_2"
                                                className="body_colors"/>
                                            <label for="trim_colors_2" onClick={(color) => {
                                                state.items['wheel'] = "#c61a34";
                                                // state.items['handle'] = "#c61a34";
                                                // state.items['strip'] = "#c61a34";
                                                state.items['side'] = "#c61a34";
                                                onChangeColors('frame',"#c61a34");
                                            }}>
                                                <span className="red"></span>
                                            </label>
                                        </div>
                                        <div className="single_color">
                                            <input type="radio" name="frame" value={'#808080'} hidden id="trim_colors_3"
                                                className="body_colors"/>
                                            <label for="trim_colors_3" onClick={(color) => {
                                                state.items['wheel'] = "#808080";
                                                // state.items['handle'] = "#808080";
                                                // state.items['strip'] = "#808080";
                                                state.items['side'] = "#808080";
                                                onChangeColors('frame',"#808080");
                                            }}>
                                                <span className="gray"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="left_side_start">
                                <div className="save_details mt-3">
                                    <label className="custom_check_box">As a Valued Customer we will include a free of charge
                                        Clear Cover for your Easy Pack Luggage.
                                        <input 
                                        type="checkbox" 
                                        name="cover_checker"
                                        value={form?.cover_checker}
                                        checked={(form?.cover_checker === "on")? true : false}
                                        onClick={(e)=>{
                                            setForm((preVal)=>({
                                                ...preVal,
                                                cover_checker : (e.target.checked)? 'on' : 'off'
                                            }));
                                        }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="small_images">
                                    <figure>
                                        <img src="https://easypackluggage.com/home/public/assets/front/images/sm_bag.png" alt=""/>
                                    </figure>
                                </div>
                            </div>
                            <div className="the_heading mt-5">
                                <label className="custom_check_box">
                                    <h6> <b> Do you want to PERSONALIZE your Luggage?</b></h6>
                                    <input 
                                        type="checkbox" 
                                        className="customize_checkbox"
                                        name="customize"
                                        value={form?.customize}
                                        checked={(form?.customize === "on")? true : false}
                                        onClick={(e)=>{
                                            setForm((preVal)=>({
                                                ...preVal,
                                                customize : (e.target.checked)? 'on' : 'off'
                                            }));
                                        }}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                                <p>Embroidery is 2 inches high, top left corner of the cover</p>
                            </div>
                            <div className="final_customized_bag">
                                <figure>
                                    <img
                                        src="https://easypackluggage.com/home/public/assets/front/images/customized.png"
                                         alt=""/>
                                </figure>
                                {
                                    (form?.customize === "on") &&
                                    <>
                                        <div className="for_the_radios">
                                            <label for="three">
                                                <input 
                                                    type="radio" 
                                                    className="letters_input"
                                                    name="letters" 
                                                    value="3"
                                                    defaultChecked={true}
                                                    checked={( parseInt(form?.letters) === 3 )? true : false}
                                                    onClick={(e)=>{
                                                        setForm((preVal)=>({
                                                            ...preVal,
                                                            letters : parseInt(e.target.value)
                                                        }));
                                                    }}
                                                    id="three"
                                                />
                                                <span>Up to 3 letters $7.95</span>
                                            </label>
                                            <label for="ten">
                                                <input 
                                                    type="radio" 
                                                    name="letters" 
                                                    className="letters_input"
                                                    value="10"
                                                    defaultChecked={false}
                                                    checked={( parseInt(form?.letters) === 10 )? true : false}
                                                    onClick={(e)=>{
                                                        setForm((preVal)=>({
                                                            ...preVal,
                                                            letters : parseInt(e.target.value)
                                                        }));
                                                    }}
                                                    id="ten"
                                                />
                                                <span>Up to 10 letters $11.95</span>
                                            </label>
                                        </div>
                                        <div className="ep_input justify-content-start">
                                            <input 
                                                type="text" 
                                                min={'1'}
                                                required={true}
                                                maxlength={form?.letters} 
                                                name="ep"
                                                onChange={(e)=>{
                                                    setForm((preVal)=>({
                                                        ...preVal,
                                                        ep : e.target.value
                                                    }));
                                                }}
                                                className="for_text_on_beg pl-2"
                                                placeholder="EP" 
                                                id="ep"
                                            />
                                        </div>
                                    </>
                                }
                                <div className="d-flex  mt-5">
                                    <button type="submit" 
                                        className="btn-header">
                                        {
                                            !loading ?
                                            'Add to Cart' : 'Adding'
                                        }
                                    </button>
                                    {/*<a href="" className="btn-header ml-3">Checkout</a>*/}
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
		</>
	);
}